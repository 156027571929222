@import "styles/variables";
@import "styles/mixings";

.swiper-container-horizontal.customSwiperClass {
  height: 100%;
  z-index: 4;

  .swiper-button-prev,
  .swiper-button-next {
    width: 30px;

    @include media-breakpoint-down(lg) {
      display: none;
    }

    &:after, &:before {
      content: '';
      display: block;
      width: 30px;
      height: 4px;
      background: $white;
      border-radius: 3px;
      position: absolute;
      right: 0;
      top: 10px;
    }

    &:after {
      transform: rotate(45deg);
      box-shadow: -5px 4px 8px rgba(6, 51, 118, 0.4);
    }

    &:before {
      transform: rotate(-45deg);
      top: 30px;
      box-shadow: -5px -4px 8px rgba(6, 51, 118, 0.4);
    }
  }

  .swiper-button-prev {
    transform: rotate(180deg);

    @media (min-width: 1400px) {
      left: 50px;
    }
  }

  > .swiper-pagination-bullets, .swiper-pagination-custom, .swiper-pagination-fraction {
    display: flex;
    align-items: center;
    width: 100%;
    padding-right: var(--bs-gutter-x, 0.75rem);
    margin-right: auto;
    margin-left: auto;

    @include media-breakpoint-down(md) {
      display: none;
    }

    @media (min-width: 768px) {
      left: 50%;
      transform: translateX(-50%);
      max-width: 720px;
      padding-left: var(--bs-gutter-x, 0.75rem);
      bottom: 30px;
      justify-content: center;
    }

    @media (min-width: 992px) {
      max-width: 960px;
      left: 50%;
      transform: translateX(-50%);
      padding-left: 80px;
      justify-content: flex-start;
      bottom: 90px;
    }

    @media (min-width: 1200px) {
      max-width: 1140px;
    }

    @media (min-width: 1400px) {
      max-width: 1320px;
    }

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      background: rgba(14, 125, 185);

      &.swiper-pagination-bullet-active {
        width: 15px;
        height: 15px;
        background: #0E7DB9;
      }
    }
  }
}
