@use "sass:math";

@import "mixings";
@import "variables";

// $theme-colors: map-remove($theme-colors, "info", "light", "dark");

// 4. Include any optional Bootstrap components as you like
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/containers";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/utilities";

// Only use the utilities we need
// stylelint-disable-next-line scss/dollar-variable-default
$utilities: map-get-multiple(
  $utilities,
    (
      // "display",
      // "order",
      // "flex",
      // "flex-direction",
      // "flex-grow",
      // "flex-shrink",
      // "flex-wrap",
      // "justify-content",
      // "align-items",
      // "align-content",
      // "align-self",
      "margin",
      "margin-x",
      "margin-y",
      "margin-top",
      "margin-end",
      "margin-bottom",
      "margin-start",
      "negative-margin",
      "negative-margin-x",
      "negative-margin-y",
      "negative-margin-top",
      "negative-margin-end",
      "negative-margin-bottom",
      "negative-margin-start",
      "padding",
      "padding-x",
      "padding-y",
      "padding-top",
      "padding-end",
      "padding-bottom",
      "padding-start",
    )
);
// Utilities
@import "~bootstrap/scss/utilities/api";

// 5. Add additional custom code here

// 5.1 Type

.h1, .h2, .h3, .h4, .h5, .h6 {
  display: block;
  margin-top: 0;
  // margin-bottom: 0.5rem;
  line-height: 1.2;
  font-weight: 700;
}

h1, .h1 {
  font-size: math.div($size-base * 56, 16);
}

h2 .h2 {
  font-size: math.div($size-base * 32, 16);
}

h3, h4, h5, h6, .h3, .h4, .h5, .h6 {
  font-size: math.div($size-base * 20, 17);
}

.h3, .h4, .h5, .h6, h3, h4, h5, h6 {
  font-weight: 600;
}

a {
  color: $primary;
  text-decoration: none;
  &:hover {
    color: $secondary;
    text-decoration: underline;
  }
}
