.smartbanner-android .smartbanner-button {
  min-width: 12%;
  color: #12B5EA;
  padding: 0;
  background: 0 0;
  border-radius: 0;
  box-shadow: none;
}

.smartbanner-android .smartbanner-button-text {
  text-align: center;
  display: block;
  padding: 0 10px;
  background: none;
  background: none;
  text-transform: none;
  text-shadow: none;
  box-shadow: none;
}
.smartbanner-container {
  white-space: normal !important;
}