@use "sass:math";

@import "styles/variables";

.langWrapper {
  position: relative;
  span {
    padding-left: $size-base*0.5;
  }
}

.langButton {
  height: 29px;
  border-radius: 5px;
  font-size: math.div($size-base * 14, 16);
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: $white;
  cursor: pointer;
  user-select: none;
  padding: 5px 0;

  &:hover{
    color: $white;
    text-decoration: none;
  }
}

.langDropdown {
  position: absolute;
  width: 72px;
  top: 100%;
  right: 0;
  z-index: 1000;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  display: none;

  &.visible {
    display: block;
  }

  a {
    height: 35px;
    font-size: math.div($size-base * 14, 16);
    width: 71px;
    display: flex;
    justify-content: flex-start;
    padding-left: $px-base * 10;
    align-items: center;
    color: $black;

    &:hover {
      color: white;
      background: $primary-blue;
    }
  }
}
