.a2a_floating_style.a2a_default_style {
  bottom: 0;
  width: 100%;
  display: inline-flex;
  place-content: center;
}

/* Hide AddToAny vertical share bar when screen is less than 980 pixels wide */
@media screen and (min-width: 576px) {
  .a2a_floating_style.a2a_default_style { display: none; }
}
@media screen and (max-width: 980px) {
  .a2a_floating_style.a2a_vertical_style { display: none; }
}